import React, {useState, useEffect} from "react"
import "./sideCollection.css"
import firebase from "firebase/app"


function SideMenuHeader(props) {
 
	const [lastIds, setLastIds] = useState([])
	const [styles, setStyles] = useState([])

	useEffect(() => {
		let isArrayEqual = true
		for (let i = 0; i < props.states.similarItems.length; ++i) {
			if (lastIds[i] !== props.states.similarItems[i]) {
				isArrayEqual = false;
				break
			} else { isArrayEqual = true }
		}
		for (let i = 0; i < lastIds.length; ++i) {
			if (lastIds[i] !== props.states.similarItems[i]) {
				isArrayEqual = false;
				break
			} else { isArrayEqual = true }
		}
		
		if (!isArrayEqual) {
			const ids = props.states.similarItems
			fetchJson(ids)
			setLastIds(ids)
		}
	}, [props.states.similarItems, lastIds])



	// Fetch Json
	const fetchJson = async (ids) => {
		let items = []
		if (ids.length === 0) setStyles([])
		for (let i = 0; i < ids.length; i++) {
			let json
			try {
				const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/gto/more-products/${ids[i]}`)
				json = await data.json()
			} catch (err) {
				console.log(`Fetch error: ${err}`)
				//   setIsFetchErr(true)
				//   console.log(isFetchErr)
				return
			}

			items.push(json)
		}

		setStyles(items)
	}



	// Styles
	const sideMenuStyle = {
		right: `${props.states.openSideMargin}px`
	}


	// Side Menu Click
	const sideMenuButClick = () => {
		props.states.closeSideMenu()
	}

	const getToClick = (item) => {
		const analytics = firebase.analytics();
		analytics.logEvent("getTo_item", {product: item})
		console.log(item)
	}


	// UI
	const collection = () => {
		return styles.map(style => (
			<div className="imageWrapper" key={`${style._id}`}>
				<img src={style.images_3x[0]} alt={`Similar item ${style._id}`}/>
				<a href={style.buyLinks[0]} onClick={() => getToClick(style._id)} target="_blank" rel="noopener noreferrer">SHOP</a>
			</div>
		))
	}

	return (
		<div className="SideCollection">
			{/* Side Menu */}
			<div className="SideWall" style={sideMenuStyle} >
				<button onClick={sideMenuButClick}>
					<div className={`topLayer-open`}></div>
					<div className={`bottomLayer-open`}></div>
				</button>

				<div className="collection">
					{collection()}
				</div>
			</div>
		</div>
	)
}


export default SideMenuHeader