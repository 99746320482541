import React from 'react';
import './404.css';
import SideMenu from "../Helper/sideMenu.js"


function Page404() {


	return (
		<div className="Page404">
			<SideMenu states={{
				gender: "women"
			}}/>
			<div className="content-wrapper">
				<p>404 <span>Page not found</span></p>
			</div>
		</div>
	)
}

export default Page404;
