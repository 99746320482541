import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Main from "./MAIN/main"
import firebase from "firebase/app"
import Contact from "./CONTACT/contact"
import Page404 from "./404/404"

function App() {

  useEffect(() => {
    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyCS2_M_RnZYFBP70P3_WjuwFH66Ci3xT0k",
      authDomain: "gettheoutfit-f4a5f.firebaseapp.com",
      databaseURL: "https://gettheoutfit-f4a5f.firebaseio.com",
      projectId: "gettheoutfit-f4a5f",
      storageBucket: "gettheoutfit-f4a5f.appspot.com",
      messagingSenderId: "483397032440",
      appId: "1:483397032440:web:f4a649ebca8f5589149e0d",
      measurementId: "G-FD8VDN8EBZ"
    };
      
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    // more at https://www.youtube.com/watch?v=iVHRy_uVtm0&t=197s
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      {/* <div> */}
        <Switch>
          <Route path="/" exact component={() => Main("women")} />
          <Route path="/men" exact component={() => Main("men")} />
          <Route path="/women" exact component={() => Main("women")} />
          <Route path="/contact" exact component={Contact} />
          <Route path="*" component={Page404} />
        </Switch>
        {/* <Footer />   */}
      {/* </div> */}
    </Router>
  )
}

export default App;
