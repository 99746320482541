import React from 'react';
import './contact.css';
import SideMenu from "../Helper/sideMenu.js"


function Contact() {


	return (
		<div className="contact">
			<SideMenu states={{
				gender: "women"
			}}/>
			<div className="mail-wrapper">
				<a href="mailto:gettheoutfit@honk.international">gettheoutfit@honk.international</a>
			</div>
		</div>
	)
}

export default Contact;
