import React, {useState, useEffect} from 'react';
import './sideMenu.css';
import {Link} from 'react-router-dom'
import OpenIcon from "../Assets/OpenIcon.svg"


function SideMenu(props) {

	const [selectedGender, setSelectedGender] = useState(props.states.gender === "men" ? 1 : 0)
	const [isGenderListOpen, setIsGenderListOpen] = useState(false)

	useEffect(() => {
	}, [])

	// On Click
	const onGenderClick = (gender) => {
		setSelectedGender(gender)
	}

	// Styles
	const menStyles = {
		zIndex: selectedGender === 1 ? "10" : "0",
		position: selectedGender === 1 ? "relative" : "absolute",
		top: isGenderListOpen ? selectedGender === 0 ? "31px" : "0" : "0"
	}

	const womenStyles = {
		zIndex: selectedGender === 0 ? "10" : "0",
		position: selectedGender === 0 ? "relative" : "absolute",
		top: isGenderListOpen ? selectedGender === 1 ? "31px" : "0" : "0"
	}

	const openIconStyles = {
		WebkitTransform: isGenderListOpen ? "rotate(180deg)" : "rotate(0deg)",
		transform: isGenderListOpen ? "rotate(180deg)" : "rotate(0deg)"
	}

	



	return (
		<div className="SideMenu">
			<div className="fixed-wrapper">
				<h1><Link className="header1" to={{pathname: "/"}}>GET THE OUTFIT</Link></h1>
				<h2>FASHION INSPIRATION</h2>
				
				<div className="filterSection">
					<button className="genderContainer" onClick={() => setIsGenderListOpen(!isGenderListOpen)}>
						<div style={{position: "relative"}}>
							<Link className="genderLink" to={{pathname: isGenderListOpen ? selectedGender === 0 ? "/men" : "" : ""}} onClick={() => onGenderClick(1)} style={menStyles}>MEN</Link>
							<Link className="genderLink" to={{pathname: isGenderListOpen ? selectedGender === 1 ? "/women" : "" : ""}} onClick={() => onGenderClick(0)} style={womenStyles}>WOMEN</Link>
						</div>
						<div className="iconWrapper">
							<img src={OpenIcon} style={openIconStyles} alt="Open women men menu icon"/>
						</div>
					</button>
	
					{/* <button className="seasonContainer">
						<div style={{position: "relative"}}>
							<button onClick={() => onGenderClick(1)} style={allStyles}>ALL</button>
							<button onClick={() => onGenderClick(0)} style={fallStyles}>FALL</button>
							<button onClick={() => onGenderClick(0)} style={springStyles}>SPRING</button>
						</div>
						<img src={OpenIcon}/>
					</button> */}
				</div>
			</div>

			<footer>
				<Link className="contact" to={{pathname: "/contact"}}>Contact</Link>
				<div className="line"></div>
				<a href="https://honk.international/">FROM<br/><span>HONK INTERNATIONAL</span></a>
			</footer>
		</div>
	)
}

export default SideMenu;
