import React, {useEffect, useState} from 'react';
import './main.css';
import SideMenu from "../Helper/sideMenu.js"
import SideCollection from "./sideCollection.js"
import firebase from "firebase/app"

function Main(props) {

	const [styles, setStyles] = useState({})
	const [isFetchErr, setIsFetchErr] = useState(false)
	const [windowSize, setWindowSize] = useState(window.innerWidth <= 1110 ? 0 
												: window.innerWidth <= 1375 ? 1
												: window.innerWidth <= 1975 ? 2
												: 3 )
	const [similarItems, setSimilarItems] = useState([])
	const [openSideMargin, setOpenSideMargin] = useState("-339")

	useEffect(() => {
		// if () {
		// 	props.history.push("/women");
		// }
		fetchJson()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", resize)
		return () => { window.removeEventListener("resize", resize) }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize])


	const resize = () => {
		if (window.innerWidth <= 1110) {
			if (windowSize !== 0) setWindowSize(0)
		} else if (window.innerWidth <= 1375) {
			if (windowSize !== 1) setWindowSize(1)
		} else if (window.innerWidth <= 1975) {
			if (windowSize !== 2) setWindowSize(2)
		} else {
			if (windowSize !== 3) setWindowSize(3)
		}
	}



	// Fetch Json
	const fetchJson = async () => {
		let items
		try {
		  const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/gto/${props}`)
		//   const data = await fetch(`http://localhost:3000/api/gto/${props}`)
		  items = await data.json()
		} catch (err) {
		  console.log(`Fetch error: ${err}`)
		  setIsFetchErr(true)
		  console.log(isFetchErr)
		  return
		}

		setStyles(items)
	}


	// On Click
	const onSimilarItemsClick = (ids) => {
		setOpenSideMargin("0")

		if (ids[0] === "") setSimilarItems([])
		else setSimilarItems(ids)
	}

	const closeSideMenu = () => {
		setOpenSideMargin("-339")
	}

	const getToClick = (item) => {
		const analytics = firebase.analytics();
		analytics.logEvent("getTo_item", {product: item})
		console.log(item)
	  }

	// UI
	const images = () => {
		return styles.map(style => 
			<div className="mainImageWrapper" key={`${styles._id}`}>
				<img src={style.imgURL_2x} alt={`Fashion Outfit ${style.season} ${style.id}`} key={`Fashion Outfit ${style.season} ${style.id}`}/>
				<a className="sorceTag" href={`https://www.instagram.com/${style.creatorID}`} target="_blank" rel="noopener noreferrer">SOURCE<span> @{style.creatorID}</span></a>
			</div>	
		)
	}

	const items = (collectionCount) => {
		const sampleArray = []
		for (let i = 1; i <= collectionCount; i++) {
			sampleArray.push(i)
		}

		return styles.map(style => 
			<div className="itemCollection" key={`Outfit item ${style._id}`}>

				{/* MOBILE */}
				{windowSize < 1 ? 
					<div className="mainImageWrapper">
						<img src={style.imgURL_2x} alt={`Fashion Outfit ${style.season} ${style.id}`} key={`Fashion Outfit ${style.season} ${style.id}`}/> 
						<a className="sorceTag" href={`https://www.instagram.com/${style.creatorID}`} target="_blank" rel="noopener noreferrer">SOURCE<span> @{style.creatorID}</span></a>
					</div>
				: ""}

				{sampleArray.map(index => 
					<div className={`collection-${index}`} key={`Outfit item ${style._id} ${index}`}>

						{style.clothings.map(clothing => {
								if ((clothing.id - 1) % collectionCount === (index - 1)) {
									return (
										<div className="item" key={`Outfit item ${clothing._id}`}>
											<div className="imageWrapper">
												<img src={clothing.images_3x[0]} alt={`Outfit item ${clothing._id}`}/>
												<a href={clothing.buyLinks[0]} onClick={() => getToClick(clothing._id)} target="_blank" rel="noopener noreferrer">SHOP</a>
											</div>
											{clothing.similareItems.length !== 0 ? <button onClick={() => onSimilarItemsClick(clothing.similareItems)}>SIMILAR ITEMS</button> : ""}
										</div>
									)
								} else {
									return ("")
								}
							}
						)}

					</div>
				)}
			</div>
		)
	}

	return (
		<div className="Main">
			{/* IMAGES */}
			{windowSize >= 1 ? 
				<div className="imageCollection">
					{styles !== null && styles.length > 0 ? images() : ""}
				</div>
			: "" }
			

			{/* ITEMS */}
			<div className="outerItemCollection">
				{styles !== null && styles.length > 0 ? items(windowSize < 2 ? 1 : windowSize === 2 ? 2 : 3) : ""}
			</div>

			<SideMenu states={{
				gender: props
			}}/>

			<SideCollection states={{
				openSideMargin: openSideMargin,
				similarItems: similarItems,
				closeSideMenu: closeSideMenu
			}}/>
		</div>
	)
}

export default Main;
